export const getURL = () => {
  const url =
    process?.env?.URL && process.env.URL !== ''
      ? process.env.URL
      : process?.env?.VERCEL_URL && process.env.VERCEL_URL !== ''
      ? process.env.VERCEL_URL
      : 'http://localhost:3000';
  return url.includes('http') ? url : `https://${url}`;
};

export const postData = async ({ url, token, data = {} }) => {
  const res = await fetch(url, {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json', token }),
    credentials: 'same-origin',
    body: JSON.stringify(data)
  });

  if (res.error) {
    throw res.error;
  }

  return res.json();
};

export const toDateTime = (secs) => {
  var t = new Date('1970-01-01T00:30:00Z'); // Unix epoch start.
  t.setSeconds(secs);
  return t;
};

export const toMMDDYYYY = (date) => {
  var dateObj = new Date(date);
  var options = { month: 'short', day: 'numeric', year: 'numeric' };
  var newDate = new Intl.DateTimeFormat('en-US', options).format(dateObj);
  return newDate;
};

export const base64Encode = (unencoded) => {
  // return new Buffer(unencoded || '').toString('base64');
  return Buffer.from(unencoded || '').toString('base64');
};

export const base64UrlEncode = (unencoded) => {
  var encoded = base64Encode(unencoded);
  return encoded.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/g, '');
};

export const base64convert = (files) => {
  //   console.clear();
  const reader = new FileReader();
  reader.onload = (e) => {
    // console.log(e.target.result);
  };
  reader.readAsDataURL(files[0]);
};

export const getUserStatus = (trial, subscription, userDetails) => {
  if (userDetails && userDetails.status === 'deleted') return 'user_deleted';

  // if (trial && subscription) {
  //   if (subscription.status === 'deleted') {
  //     if (subscription && subscription.cancel_at_period_end) {
  //       if (Date.now() < new Date(subscription.cancel_at))
  //         return 'subscription_cancelled';
  //       else return 'subscription_cancelled_end';
  //     }
  //   }
  // }

  if (!trial && !subscription) return 'free';

  if (!subscription && trial) {
    if (trial.products.id === '0') {
      return 'downgrade_free';
    }

    if (Date.now() < new Date(trial.trial_end)) return 'trial';
    //TODO when use upgraded before trial ended then cancelled
    else return 'trial_ended';
  }

  if (
    subscription &&
    (subscription.status === 'active' ||
      subscription.status === 'past_due' ||
      subscription.status === 'trialing')
  )
    return 'subscription';

  if (subscription && subscription.status === 'paused') {
    return 'subscription_paused';
  }

  if (subscription && subscription.cancel_at_period_end) {
    if (Date.now() < new Date(subscription.cancel_at))
      return 'subscription_cancelled';
    else return 'subscription_cancelled_end';
  }

  return 'error';
};

export function sleep(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}
